.memberClub {
  font-family: PingFangSC-Regular;
}
.memberClub .diglogPStyle {
  margin: 0.2rem 0.2rem 0.2rem 2rem;
  font-size: 16px;
}
.memberClub .dialogStyle {
  margin: 0.4rem 0.4rem 0.1rem 0.4rem;
  padding: 0 0 0.2rem;
  border-bottom: 1px solid #f3f0f3;
}
.memberClub .diglogPStyle1 {
  font-size: 16px;
  margin: 0.3rem 0.4rem;
  display: flex;
}
.memberClub .diglogPStyle1 .label {
  width: 9em;
}
.memberClub .diglogPStyle1 .psdInput {
  font-size: 16px;
  border: 0;
  flex: 1;
  display: block;
  width: 100%;
}
.memberClub .buttonOk {
  margin-top: 5.5rem;
  color: #333333;
  font-size: 0.32rem;
  border: 0;
  width: 100%;
  height: 0.88rem;
  line-height: 0.88rem;
  background: #FAD538;
  border-radius: 0.16rem;
}
.memberClub .content {
  position: relative;
  padding-top: 0.88rem;
  padding-top: calc(env(safe-area-inset-top) + 0.88rem);
  left: 0;
  width: 100%;
}
.memberClub .content::before {
  content: "";
  height: 2.95rem;
  height: calc(env(safe-area-inset-top) + 2.95rem);
  width: 100%;
  background: url(../../assets/imgs/new_color/club_bg.png) no-repeat center top;
  background-size: 100% 100%;
  display: inline-block;
  position: absolute;
  top: 0;
}
.memberClub .userInfo {
  overflow: hidden;
  margin: 0 0.5rem;
  margin-top: 0.2rem;
  position: relative;
  z-index: 10;
}
.memberClub .userInfo .level {
  margin-left: 0.2rem;
}
.memberClub .userInfo .level span {
  width: 1.91rem;
  height: 0.37rem;
  border-radius: 0.27rem;
  font-size: 0.24rem;
  text-align: center;
  line-height: 0.37rem;
  color: #D7B986;
  background-image: url(../../assets/imgs/new_color/VIP_bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 0.03rem 0.17rem;
}
.memberClub .userInfo .userHead {
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
}
.memberClub .userInfo .userName {
  margin-left: 0.2rem;
  font-size: 0.32rem;
  color: #6E471C;
}
.memberClub .userInfo .vipBox {
  margin-left: 0.2rem;
}
.memberClub .userInfo .vipBox .vipLevel {
  width: 1rem;
  height: 0.4rem;
  position: relative;
  font-size: 0.24rem;
  font-family: Bahnschrift;
  display: inline-block;
  color: #6E471C;
}
.memberClub .userInfo .vipBox .vipLevel img {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
}
.memberClub .userInfo .vipBox .vipLevel span {
  position: relative;
  z-index: 11;
  display: block;
  text-align: center;
  line-height: 0.4rem;
  font-weight: bold;
  font-size: 0.26rem;
}
.memberClub .userInfo .vipBox .expireDay {
  color: #6E471C;
  line-height: 0.35rem;
}
.memberClub .userInfo .vipBox .expireDay .span1 {
  font-size: 0.24rem;
  font-weight: normal;
}
.memberClub .userInfo .vipBox .expireDay .span2 {
  margin-left: 0.1rem;
  font-size: 0.24rem;
}
.memberClub .banli {
  margin: 0 0.2rem;
  margin-top: 0.7rem;
}
.memberClub .banli .top {
  padding: 0.2rem 0;
  display: flex;
  align-items: center;
}
.memberClub .banli .top .kuai {
  margin-right: 0.22rem;
  width: 5px;
  height: 0.26rem;
  background-color: #F8D33A;
}
.memberClub .banli .top .mcTitle {
  font-size: 0.32rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
.memberClub .banli .changeStyles {
  border: 1px solid #EFBA0D;
  border-radius: 0.16rem;
  text-align: center;
  padding: 0.3rem 0;
  overflow: hidden;
}
.memberClub .banli .changeStyles.checked {
  color: #fff;
  background-image: linear-gradient(135deg, #FAE341 0%, #F9DB45 100%);
  background-color: ;
  position: relative;
  border: none;
}
.memberClub .banli .changeStyles.checked .dataPrice {
  color: #333333;
}
.memberClub .banli .mainBackColor1 p {
  color: #fff;
}
.memberClub .banli .dataTitle {
  color: #333333;
  font-size: 0.28rem;
}
.memberClub .banli .dataPrice {
  color: #EFBA0D;
  font-size: 0.44rem;
  font-weight: bold;
  margin-top: 0.1rem;
}
.memberClub .banli .dataPriceOld {
  color: #666666;
  font-size: 0.28rem;
  text-decoration: line-through;
  margin-top: 0.1rem;
}
